

//レンタル機種選択
// 初期状態
// const t = $('input[name="item"]:checked').attr('id');
// $(`[data-target-usage="${t}"]`).addClass('is-active');
// $(".c-form--model__box").not(`[data-target-usage="${t}"]`).removeClass('is-active');

//クリック時
$('[data-trigger-type]').on('click', function() {
  
  const r = $('input[name="type"]:checked').attr('id');
  $(`[data-target-type="${r}"]`).addClass('is-active');
  $("[data-target-type]").not(`[data-target-type="${r}"]`).removeClass('is-active');
})

$('[data-trigger-usage]').on('click', function() {
  const r = $('input[name="usage"]:checked').attr('id');
  $(`[data-target-usage="${r}"]`).addClass('is-active');
  $("[data-target-usage]").not(`[data-target-usage="${r}"]`).removeClass('is-active');
})

$('[data-trigger-plan]').on('click', function() {
  const r = $('input[name="plan"]:checked').attr('id');
  $(`[data-target-plan="${r}"]`).addClass('is-active');
  $("[data-target-plan]").not(`[data-target-plan="${r}"]`).removeClass('is-active');
})

//よくある質問
$('.js-menu__item__link').each(function () {
  $(this).on('click', function () {
    $("+.contentsForm__terms__box", this).slideToggle();
    return false;
  });
});

//同意する
$('[data-trigger-acceptance]').on('click', function() {
  if ($('input[name="acceptance"]').prop('checked')) {
    $(`[data-target-acceptance]`).addClass('is-active');
  } else {
    $(`[data-target-acceptance]`).removeClass('is-active');
  }  
})


$('.header__btn').on('click', function () {
  $('html').toggleClass('scroll-prevent')
  if ($(this).hasClass('is-active')) {
    $(this).removeClass('is-active');
    $('#nav-list').slideUp();
  } else {
    $(this).addClass('is-active');
    $('#nav-list').slideDown();
  }
});


$('.contentsKv__list').slick({
  dotsClass: 'slick-dots--bike',
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  infinite: true,
  arrows: false,
  autoplay:true,
  autoplaySpeed:5000,
  centerMode: true,
  centerPadding: '20%',
  responsive: [
    {
      breakpoint: 1524,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '10%',
      }
    },      
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '0',
        dots: false,
      }
    },
  ]
});

//タブ切り替え
$(".tab_label").on("click",function(){
  var $th = $(this).index();
  $(".tab_label").removeClass("active");
  $(".tab_panel").removeClass("active");
  $(this).addClass("active");
  $(".tab_panel").eq($th).addClass("active");
});

//よくある質問
$('.js-menu__item__link').each(function () {
  $(this).on('click', function () {
    $("+.contentsQue__box", this).slideToggle();
    $(this).toggleClass("is-active")
    return false;
  });
});

$('header a[href^="#"]').click(function () {
  $('html').removeClass('scroll-prevent')
  $('.header__btn').removeClass('is-active');
  $('#nav-list').slideUp();
  var speed = 500;
  var href = $(this).attr("href");
  var target = $(href == "#" || href == "" ? 'html' : href);
  var position = target.offset().top;
  $("html, body").animate({ scrollTop: position }, speed, "swing");
  return false;
});